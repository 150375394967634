import * as React from "react";
import RouteView, { IRouteViewProps } from "./../Route";
import { History } from "history";
import './_styles.less';
import { useLocation } from "react-router-dom";
import request from '@/utils/request';

interface ILayoutProps extends IRouteViewProps {
  history: History;
}

async function wxConfig() {
  const ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') < 0) return;

  if (window.wx) {
    console.info(window.location)
    const WXConfig = await request('/api/Share', {
      params: {
        url: window.location.href,
      }
    })
    window.wx.config(WXConfig.data);
  }
}

const Layout = (props: ILayoutProps) => {
  const location = useLocation();

  React.useEffect(() => {
    if (!location.key) return;
    console.log('路由变化,调用wxConfig', location,location.key);
    wxConfig();
    return () => { console.log('useEffect unload 路由变化,调用wxConfig') };
  }, [location]);

  return (
    <div className="layout">
      <RouteView {...props} />
    </div>
  );
};

export default Layout;