import { lazy } from 'react';
import Layout from './../Layout';

const RouteConfig = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/home",
        component: lazy(() => import('./../pages/Home')),
      },
      {
        path: "/station/:id",
        component: lazy(() => import("./../pages/Station")),
      },
      {
        path: "/site/:id",
        component: lazy(() => import("./../pages/Site")),
      },
      {
        path: "/location",
        component: lazy(() => import("./../pages/Location")),
      },
      // {
      //   path: "/basic/page3",
      //   component: lazy(() => import("../pages/Page3")),
      // },
      { path: "/", redirect: "/home" },
    ],
  },
  {
    path: "/",
    redirect: "/home",
  },
];

export default RouteConfig;
